.flex {
    display: flex;
}
.f-row {
    flex-direction: row;
}
.f-col {
    flex-direction: column;
}
.f-cont-wide {
    justify-content: space-between;
    width: 100%;
}
.nowrap {
    flex-wrap: nowrap;
}
.fwrap {
    flex-wrap: wrap;
}
.w100 {
    width: 100%;
}
.h100 {
    height: 100%;
}
.hfc {
    height: fit-content;
}
.p1rem {
    padding: 1rem;
}
.j-c-start {
    justify-content: flex-start !important;
}
.j-c-end {
    justify-content: flex-end !important;
}