/* You can add global styles to this file, and also import other style files */
@use "@ansys/awc-angular/design/styles/awc";
@import "@ansys/awc-angular/design/styles/doc-overloads/index.css";
@import "@ansys/awc-angular/design/styles/themes/css/default.css";
@import "./app/shared/styles/_layout.scss";
@import "./app/shared/styles/_variables";

app-home, app-login, app-dashboard {
    height: 100vh;
    width: 100%;
    display: flex;
}
app-login-form {
    height: 100%;
    display: flex;
}
.title-with-icon {
    display: flex;
    grid-gap: 10px;
    h6 {
        font-size: 16px;
    }
}

.hoverlayer {
    display: none !important;
}
html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .awc-tree-item-container {
    box-sizing: border-box !important;
  }
  ::ng-deep {
    .awc-tree-item-container {
        box-sizing: border-box !important;
    }
  }

::ng-deep .awc-badge-container {
    width: 100% !important;
}


body,
label,
div,
span {
  color: var(--awc-theme-foreground-neutral-default);
  font-family: var(--awc-common-typography-medium-body-regular-font);
  font-size: var(--awc-common-typography-medium-body-regular-size);
  line-height: var(--awc-common-typography-medium-body-regular-line-height);
  font-weight: var(--awc-common-typography-medium-body-regular-weight);
  transition: color .2s
}